import domReady from '@roots/sage/client/dom-ready';
import Headroom from "headroom.js";

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  // headroom
  const header = document.querySelector('.site-header');
  const headroom = new Headroom(header, {
    offset: 100,
    tolerance: 5,
  });

  headroom.init();





});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
